import React from "react"
import { Link } from "gatsby"
import Layout from "../../../components/layout"
import SEO from "../../../components/seo"
import SectionWrap from "../../../components/sectionWrap"
import Introtext from "../../../components/introtext"
import Headline from "../../../components/headline"
import * as ffsStyles from "../../../components/sectionWrap/sectionWrap.module.css"
import ContentmoduleAbs from "../../../components/contentmoduleAbs"
import {AnchorLink} from "gatsby-plugin-anchor-links"
// Background-Images
import firstBackgroundImage from "../../../images/greyback.jpg"
import secondBackgroundImage from "../../../images/whiteback.jpg"
import thirdBackgroundImage from "../../../images/greyback.jpg"
import fourthBackgroundImage from "../../../images/whiteback.jpg"
// Objekt-Images
import firstObjectImage from "../../../images/projekte/smart-home-konfigurator/landing/smart-home-landing.png"
import secondObjectImage from "../../../images/projekte/abb-welcome-konfigurator/landing/abb-welcome-konfigurator-landing.png"
import thirdObjectImage from "../../../images/projekte/bje-baustellenplaner/landing/bje-baustellenplaner-landing.png"
import fourthObjectImage from "../../../images/projekte/telekom-glasfaserinfo/landing/telekom-glasfaser-infopagebuilder-landing.png"

const Konfiguratoren = function (props, ref) {

  var counter = 1
  const data = [
    {
      logo: "/logos/logo-busch-jaeger-bunt.svg",
      titel: "Busch-Jaeger Smart Home Konfigurator",
      text: "Intelligentes Zuhause komfortabel geplant. ",
      slug: "/projekte/konfiguratoren/busch-jaeger-smart-home-konfigurator",
      objectImage: firstObjectImage,
      bgImage: firstBackgroundImage,
    },
    {
      logo: "/logos/logo-telekom.svg",
      titel: "Telekom Glasfaser Infopage-Builder",
      text: "Konfigurator zur Erstellung von individuellen Präsentationen zur Vermarktung von Glasfaser.",
      slug: "/projekte/konfiguratoren/telekom-glasfaser-infopage-builder",
      objectImage: fourthObjectImage,
      bgImage: fourthBackgroundImage,
    },
    {
      logo: "/logos/logo-busch-jaeger-bunt.svg",
      titel: "Busch-Baustellenplaner",
      text:
        "Einfache Planung von Schalter- und Steckdosenkombinationen für Endkunden und Elektroinstallateure bei Renovation und Neubau.",
      slug: "/projekte/konfiguratoren/busch-jaeger-baustellenplaner",
      objectImage: thirdObjectImage,
      bgImage: thirdBackgroundImage,
    },
    {
      logo: "/logos/logo-abb.svg",
      titel: "ABB Welcome Konfigurator",
      text: "Komfortable Planung einer ABB-Welcome® Türkommunikationsanlage.",
      slug: "/projekte/konfiguratoren/abb-welcome-konfigurator",
      objectImage: secondObjectImage,
      bgImage: secondBackgroundImage,
    },
  ]

  return (
    <Layout>
      <SEO
        title="Konfiguratoren"
        description="Produktkonfiguratoren von Q:marketing"
      />

      <div className="page-container-offset">
        {/* 
          head
        */}
        <div className="container-fluid">
          <SectionWrap id="abc" bgCol={"#fff"} className="height50 my-5">
            <div className={`row ${ffsStyles.vcenterRowContent}`}>
              {/* left */}
              <div className={`col-12 col-md-8 px10`}>
                <Headline
                  titel="Konfiguratoren"
                  showCta={false}
                  skyhead={false}
                  text={<>Mit den von uns entwickelten maßgeschneiderten, intuitiven Tools planen Ihre Kunden individuelle Produktlösungen.<p className="pt-2"><AnchorLink to="/projekte/konfiguratoren/#beispiele" title="↓ Beispiele" className="" /></p></>}
                />
              </div>
            </div>
          </SectionWrap>
        </div>

        <div id="beispiele"></div>        

        {data.map(e => {
          counter++
          var result = counter % 2 ? true : false

          return (
            <div className="container-fluid">
              <SectionWrap bgImg={e.bgImage} bgCol={"#fff"} className="height80 projektdetailkachel">
                <div className={`row ${ffsStyles.vcenterRowContent} bg-q-grey-mobile`}>
                  <div className={`col-12 mx-0 px-0`}>
                    {/* Vordergrund */}
                    <ContentmoduleAbs>
                      <div className={`row ${ffsStyles.vcenterRowContent}`}>
                        <div className={`col-md-6 mx-0 px-0 vorschaubild ${result ? "order-md-last" : ""}`}>
                          <img src={e.logo} className="d-md-none xyz logopic" />
                          <Link to={e.slug}><img src={e.objectImage} style={{ objectFit: "contain", pointerEvents: "visible" }} /></Link>
                        </div>
                        <div className={`col-md-6 mx-0 px-0 textpart ${result ? "order-md-first" : ""}`}>
                          <Introtext
                            skypic={e.logo}
                            skyhead={false}
                            titel={e.titel}
                            text={e.text}
                            linklabel="Mehr erfahren"
                            link={e.slug}
                          />
                        </div>
                      </div>
                    </ContentmoduleAbs>
                  </div>
                </div>
              </SectionWrap>
            </div>
          )

        })}
      </div>
    </Layout>
  )
}

export default Konfiguratoren
